import { Controller } from "@hotwired/stimulus"
import $ from "jquery"
import Select2 from "select2"

export default class extends Controller {
  connect() {
    Select2()
    $('.js-select2').select2({
      multiple: true,
      placeholder: "施術を選択"
    });
  }
}
