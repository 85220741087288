import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("change", this.handleChange.bind(this))
  }

  handleChange(event) {
    event.preventDefault()
    this.element.submit()
  }
}
